import http from '../clients/Axios'

export default class SaldoAwalBarangService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/account/saldo/barang/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    return await http.get(`/account/saldo/barang${param}`)
  }

  async add(form) {
    return await http.post('/account/saldo/barang', form)
  }

  async update(form, id) {
    return await http.post(`/account/saldo/barang/${id}`, form)
  }

  async delete(id) {
    return await http.delete(`/account/saldo/barang/${id}`)
  }

  async exportPdf() {
    return http({
      url: `/account/saldo/barang/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async exportExcel() {
    return http({
      url: `/account/saldo/barang/export`,
      method: 'GET',
      responseType: 'blob',
    })
  }

  async import(value) {
    let formData = new FormData()
    formData.append('import_file', value)

    return await http.post(`/account/saldo/barang/import`, formData)
  }

  async templateImport() {
    return http({
      url: `/account/saldo/barang/import/download`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
