<template>
  <div>
    <DataTable
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      lazy
      row-hover
      paginator
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-sm"
      dataKey="id"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      scrollable
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        field="bukti_no"
        header="No Bukti"
        style="min-width: 250px"
        sortable
      >
        <template #body="{ data }">
          {{ data.bukti_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="giro_between"
        field="bukti_at"
        header="Tanggal"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.bukti_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="penerima_nama"
        header="Penerima Giro"
        style="min-width: 250px"
        sortable
      >
        <template #body="{ data }">
          {{ data.penerima_nama }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column field="nama_bank" header="Nama Bank" style="min-width: 170px" />
      <Column field="rekening" header="No. Rekening" style="min-width: 170px" />
      <Column field="giro_no" header="No. Giro" style="min-width: 250px">
        <template #body="{ data }">
          {{ data.giro_no }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        dataType="date"
        filter-field="overdue_between"
        field="giro_at"
        header="Jatuh Tempo"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.giro_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="nominal"
        header="Nominal"
        style="min-width: 210px"
        sortable
      >
        <template #body="{ data }">
          {{ formatCurrency(data.nominal) }}
        </template>
        <template
          #filter="{ filterModel, filterCallback }"
          style="justify-content: flex-start"
        >
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="giro_status"
        header="Status"
        style="min-width: 220px"
        sortable
      >
        <template #body="{ data }">
          <div :class="'status-badge status-' + data.giro_status">
            {{ data.giro_status }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="[
              { value: 'cair', label: 'cair' },
              { value: 'batal', label: 'batal' },
              { value: 'outstand', label: 'outstand' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter text-right"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="giro_status_at"
        header="Tanggal Proses"
        style="min-width: 12rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.giro_status_at) }}
        </template>
      </Column>
      <Column
        field="giro_keterangan"
        header="Keterangan"
        style="min-width: 250px"
      />
      <Column style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text"
            @click="$emit('edit', data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import CalendarFilter from '@/components/CalendarFilter'
import { FilterMatchMode } from 'primevue/api'
import { formatCurrency, formatDate } from '@/helpers'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    accounts: {
      type: Array,
      default() {
        return []
      },
    },
  },
  components: { CalendarFilter },
  data() {
    return {
      options: this.gridOptions,
      filters: {
        penerima_nama: { value: null, matchMode: FilterMatchMode.CONTAINS },
        bukti_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        bukti_at: { value: null, matchMode: FilterMatchMode.CONTAINS },
        giro_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        giro_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nominal: { value: null, matchMode: FilterMatchMode.CONTAINS },
        overdue_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        giro_status: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
