<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Jurnal Umum</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small"> Keuangan / Jurnal Umum</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          icon="pi pi-plus"
          label="Jurnal Umum"
          class="mr-2"
          @click="$router.push({ name: 'JurnalUmumAdd' })"
        />
        <Button
          :loading="isLoadingExportExcel"
          icon="pi pi-file-excel"
          label="Export"
          class="p-button-outlined mr-2"
          @click="exportDataExcel"
        />
        <!-- <Button
          :loading="isLoadingExportPdf"
          icon="pi pi-print"
          label="Print"
          class="p-button-outlined"
          @click="exportDataPdf"
        /> -->
      </div>
      <grid-jurnal-umum
        :items="items"
        :loading="isLoading"
        :total="totalRecords"
        :grid-options="options"
        @request="onRequestData"
        @edit="onEditData"
        @delete="onConfirmDeletion"
        @print="onPrint"
      />
    </div>
    <Dialog
      header="Hapus Jurnal"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Jurnal <strong>{{ form.ju_no }}</strong> akan dihapus. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="this.dialogHapus = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteData"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import AccountService from '@/services/AccountService'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import GridJurnalUmum from '@/components/keuangan/GridJurnalUmum'
import { saveAs } from 'file-saver'

export default {
  components: {
    GridJurnalUmum,
    Hotkey,
  },
  data() {
    return {
      dataService: null,
      dialogHapus: false,
      dialogViewer: false,
      faktur: 0,
      pdfSource: '',
      pdfTitle: 'PDF Viewer',
      pdfFilename: '',
      isLoading: false,
      isLoadingSave: false,
      isLoadingExportPdf: false,
      isLoadingExportExcel: false,
      items: [],
      form: {},
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: 'ju_at',
        sortOrder: 1,
        filters: null,
      },
    }
  },
  created() {
    this.dataService = new AccountService()
  },
  mounted() {
    this.loadGridData()
  },
  methods: {
    onRequestData(e) {
      this.options = e
      this.loadGridData()
    },
    loadGridData() {
      const page = this.options.page
      const perPage = this.options.rows
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoading = true
      this.dataService
        .paginateJurnal(page, perPage, sortField, sortOrder, filters)
        .then((res) => {
          this.items = res.data.data
          this.totalRecords = res.data.total
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Jurnal Umum', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onEditData(data) {
      console.log(data)
      this.$router.push({
        name: 'JurnalUmumEdit',
        params: { id: data.id },
      })
    },
    onConfirmDeletion(data) {
      this.form = data
      this.dialogHapus = true
    },
    onDeleteData() {
      this.dataService
        .deleteJurnal(this.form.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Jurnal Umum',
              detail: 'Data berhasil dihapus.',
              life: 3000,
            })
            this.loadGridData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Jurnal Umum', this)
        })
      this.form = {}
      this.dialogHapus = false
    },
    onPrint(data) {
      this.isLoadingExport = true
      this.dataService
        .printJurnal(data.id)
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Jurnal Umum',
              detail: 'Gagal mengunduh Jurnal Umum.',
              life: 3000,
            })
          } else {
            const blob = new Blob([res.data], { type: 'application/pdf' })
            const pdfUrl = URL.createObjectURL(blob)
            this.pdfSource = pdfUrl + '#toolbar=0'
            this.pdfFilename = `jurnal-umum.pdf`
            this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
            this.dialogViewer = true
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Jurnal Umum', this)
        })
      this.isLoadingExportPdf = false
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    exportDataPdf() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoadingExport = true
      this.dataService
        .exportJurnalPdf(sortField, sortOrder, filters)
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Jurnal Umum',
              detail: 'Gagal mengunduh Jurnal Umum.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `jurnal-umum.pdf`)
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Jurnal Umum', this)
        })
      this.isLoadingExportPdf = false
    },
    exportDataExcel() {
      const sortField = this.options.sortField
      const sortOrder = this.options.sortOrder
      const filters = this.options.filters

      this.isLoadingExportExcel = true
      this.dataService
        .exportJurnalExcel(sortField, sortOrder, filters)
        .then((res) => {
          if (res.status !== 200) {
            this.$toast.add({
              severity: 'error',
              summary: 'Jurnal Umum',
              detail: 'Gagal mengunduh Jurnal Umum.',
              life: 3000,
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'jurnal-umum.xlsx')
            document.body.appendChild(link)
            link.click()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Export Jurnal Umum', this)
        })
        .finally(() => (this.isLoadingExportExcel = false))
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        this.$router.push({ name: 'SaldoAwalHutangAdd' })
      }
    },
  },
}
</script>
